var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"max-width":"480","loading":_vm.loading}},[_c('v-card-title',[_vm._v("Filters")]),_c('v-card-text',[(_vm.reports)?_c('v-select',{attrs:{"items":_vm.modifiedReports,"label":"Report","prepend-icon":"mdi-calendar"},model:{value:(_vm.report),callback:function ($$v) {_vm.report=$$v},expression:"report"}}):_vm._e(),(_vm.data)?[_c('v-select',{attrs:{"items":_vm.data.customers,"item-text":"name","item-value":"name","label":"Customers","multiple":"","chips":"","prepend-icon":"mdi-briefcase-account"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('v-checkbox',{attrs:{"label":"Of Interest"},model:{value:(_vm.interest),callback:function ($$v) {_vm.interest=$$v},expression:"interest"}}),_c('v-select',{attrs:{"items":_vm.units,"label":"Unit","prepend-icon":"mdi-harddisk"},model:{value:(_vm.unit),callback:function ($$v) {_vm.unit=$$v},expression:"unit"}})]:_vm._e()],2)],1)],1)],1),_c('v-row',[_c('v-col',[(_vm.data)?_vm._l((_vm.filteredData),function(customer){return _c('v-card',{key:customer.name,staticClass:"mb-6",staticStyle:{"overflow-x":"auto"}},[_c('v-card-title',[_vm._v(" "+_vm._s(customer.name)+" "),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attr = ref.attr;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"href":("mailto:" + (_vm.getAllMailsByCustomer(customer.name))),"icon":"","color":"primary"}},'v-btn',attr,false),on),[_c('v-icon',[_vm._v("mdi-email")])],1)]}}],null,true)},[_c('span',[_vm._v("Mail to All")])])],1),_c('v-simple-table',{staticStyle:{"min-width":"1024px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Resource")]),_c('th',[_vm._v("Contact")]),_c('th',[_vm._v("Client")]),_c('th',[_vm._v("Quota")]),_c('th',[_vm._v("Usage")]),_c('th')])]),_c('tbody',_vm._l((customer.resources),function(resource){return _c('tr',{key:resource.name},[(!_vm.interest || (_vm.interest && _vm.checkEntryState(resource)))?[_c('td',[_c('v-icon',[_vm._v(_vm._s(("mdi-" + (resource.type))))]),_vm._v(" "+_vm._s(resource.name)+" ")],1),_c('td',[(resource.contact === '-2')?[(resource.contact === '-2')?_c('v-tooltip',{attrs:{"right":"","color":"orange"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attr = ref.attr;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"orange"}},'v-icon',attr,false),on),[_vm._v("mdi-alert-circle")])]}}],null,true)},[_c('span',[_vm._v("Not Set")])]):_vm._e(),_vm._v(" Not Set ")]:(resource.contact !== '-1')?_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attr = ref.attr;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"href":("mailto:" + (resource.contact)),"icon":"","color":"primary"}},'v-btn',attr,false),on),[_c('v-icon',[_vm._v("mdi-email")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(resource.contact))])]):_vm._e()],2),_c('td',[(_vm.checkClientVersion(resource.client))?_c('v-tooltip',{attrs:{"right":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attr = ref.attr;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attr,false),on),[_vm._v("mdi-alert")])]}}],null,true)},[_c('span',[_vm._v("Old Version")])]):_vm._e(),(resource.client === '-2')?[_c('v-tooltip',{attrs:{"right":"","color":"orange"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attr = ref.attr;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"orange"}},'v-icon',attr,false),on),[_vm._v("mdi-alert-circle")])]}}],null,true)},[_c('span',[_vm._v("Not Detected")])]),_vm._v(" Not Detected ")]:(resource.client !== '-1')?[_vm._v(_vm._s(resource.client.replace('mirall/', '')))]:_vm._e()],2),_c('td',[_vm._v(_vm._s(resource.quota === '-3' ? 'Unlimited' : ("" + (_vm.convertToUnit(resource.quota)))))]),_c('td',[(_vm.convertToPercent(resource.quota, resource.usage) >= _vm.breakPoints.warning)?_c('v-tooltip',{attrs:{"right":"","color":_vm.convertToColor(_vm.convertToPercent(resource.quota, resource.usage))},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attr = ref.attr;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.convertToColor(_vm.convertToPercent(resource.quota, resource.usage))}},'v-icon',attr,false),on),[(_vm.convertToPercent(resource.quota, resource.usage) >= _vm.breakPoints.error)?[_vm._v("mdi-alert")]:[_vm._v("mdi-alert-circle")]],2)]}}],null,true)},[_c('span',[(_vm.convertToPercent(resource.quota, resource.usage) >= _vm.breakPoints.error)?[_vm._v("Full")]:[_vm._v("Almost Full")]],2)]):_vm._e(),_vm._v(" "+_vm._s(resource.usage === '-2' ? 'Not Initialized' : ("" + (_vm.convertToUnit(resource.usage))))+" ")],1),_c('td',[(resource.quota !== '-3' && resource.usage !== '-2')?_c('v-progress-linear',{staticStyle:{"min-width":"32px"},attrs:{"value":_vm.convertToPercent(resource.quota, resource.usage),"height":"25","striped":"","color":_vm.convertToColor(_vm.convertToPercent(resource.quota, resource.usage))}},[_c('strong',[_vm._v(_vm._s(_vm.convertToPercent(resource.quota, resource.usage))+" %")])]):_vm._e()],1)]:_vm._e()],2)}),0)]},proxy:true}],null,true)})],1)}):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }