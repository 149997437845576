<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card max-width="480" :loading="loading">
          <v-card-title>Filters</v-card-title>
          <v-card-text>
            <v-select v-if="reports" v-model="report" :items="modifiedReports" label="Report" prepend-icon="mdi-calendar" />
            <template v-if="data">
              <v-select v-model="filter" :items="data.customers" item-text="name" item-value="name" label="Customers" multiple chips prepend-icon="mdi-briefcase-account" />
              <v-checkbox v-model="interest" label="Of Interest" />
              <v-select v-model="unit" :items="units" label="Unit" prepend-icon="mdi-harddisk" />
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <template v-if="data">
          <v-card v-for="customer in filteredData" :key="customer.name" class="mb-6" style="overflow-x: auto;">
            <v-card-title>
              {{ customer.name }}
              <v-spacer />
              <v-tooltip left color="primary">
                <template v-slot:activator="{ on, attr }">
                  <v-btn v-on="on" v-bind="attr" :href="`mailto:${getAllMailsByCustomer(customer.name)}`" icon color="primary">
                    <v-icon>mdi-email</v-icon>
                  </v-btn>
                </template>
                <span>Mail to All</span>
              </v-tooltip>
            </v-card-title>
            <v-simple-table style="min-width: 1024px;">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Resource</th>
                    <th>Contact</th>
                    <th>Client</th>
                    <th>Quota</th>
                    <th>Usage</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="resource in customer.resources" :key="resource.name">
                    <template v-if="!interest || (interest && checkEntryState(resource))">
                      <td>
                        <v-icon>{{ `mdi-${resource.type}` }}</v-icon>
                        {{ resource.name }}
                      </td>
                      <td>
                        <template v-if="resource.contact === '-2'">
                          <v-tooltip v-if="resource.contact === '-2'" right color="orange">
                            <template v-slot:activator="{ on, attr }">
                              <v-icon v-on="on" v-bind="attr" color="orange">mdi-alert-circle</v-icon>
                            </template>
                            <span>Not Set</span>
                          </v-tooltip>
                          Not Set
                        </template>
                        <v-tooltip v-else-if="resource.contact !== '-1'" right color="primary">
                          <template v-slot:activator="{ on, attr }">
                            <v-btn v-on="on" v-bind="attr" :href="`mailto:${resource.contact}`" icon color="primary">
                              <v-icon>mdi-email</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ resource.contact }}</span>
                        </v-tooltip>
                      </td>
                      <td>
                        <v-tooltip v-if="checkClientVersion(resource.client)" right color="red">
                          <template v-slot:activator="{ on, attr }">
                            <v-icon v-on="on" v-bind="attr" color="red">mdi-alert</v-icon>
                          </template>
                          <span>Old Version</span>
                        </v-tooltip>
                        <template v-if="resource.client === '-2'">
                          <v-tooltip right color="orange">
                            <template v-slot:activator="{ on, attr }">
                              <v-icon v-on="on" v-bind="attr" color="orange">mdi-alert-circle</v-icon>
                            </template>
                            <span>Not Detected</span>
                          </v-tooltip>
                          Not Detected
                        </template>
                        <template v-else-if="resource.client !== '-1'">{{ resource.client.replace('mirall/', '') }}</template>
                      </td>
                      <td>{{ resource.quota === '-3' ? 'Unlimited' : `${convertToUnit(resource.quota)}` }}</td>
                      <td>
                        <v-tooltip v-if="convertToPercent(resource.quota, resource.usage) >= breakPoints.warning" right :color="convertToColor(convertToPercent(resource.quota, resource.usage))">
                          <template v-slot:activator="{ on, attr }">
                            <v-icon v-on="on" v-bind="attr" :color="convertToColor(convertToPercent(resource.quota, resource.usage))">
                              <template v-if="convertToPercent(resource.quota, resource.usage) >= breakPoints.error">mdi-alert</template>
                              <template v-else>mdi-alert-circle</template>
                            </v-icon>
                          </template>
                          <span>
                            <template v-if="convertToPercent(resource.quota, resource.usage) >= breakPoints.error">Full</template>
                            <template v-else>Almost Full</template>
                          </span>
                        </v-tooltip>
                        {{ resource.usage === '-2' ? 'Not Initialized' : `${convertToUnit(resource.usage)}` }}
                      </td>
                      <td>
                        <v-progress-linear v-if="resource.quota !== '-3' && resource.usage !== '-2'" :value="convertToPercent(resource.quota, resource.usage)" height="25" striped :color="convertToColor(convertToPercent(resource.quota, resource.usage))" style="min-width: 32px;">
                          <strong>{{ convertToPercent(resource.quota, resource.usage) }} %</strong>
                        </v-progress-linear>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/**
 * CONSTANTS
 * 
 * Contact
 * -1 ... not available
 * -2 ... not set
 * 
 * Client
 * -1 ... not available
 * -2 ... not detected
 * 
 * Quota
 * -3 ... unlimited
 * 
 * Usage
 * -2 ... not initialized
 */

export default {
  name: 'Usage',
  data: () => ({
    report: '',
    reports: [],
    data: undefined,
    loading: false,
    filter: [],
    interest: false,
    unit: 'GB',
    units: ['KB', 'MB', 'GB'],
    // Configuration
    minClientVersion: undefined,
    breakPoints: {}
  }),
  mounted () {
    fetch('config.json')
      .then(response => response.text())
      .then(text => {
        let data = JSON.parse(text)
        this.minClientVersion = data.minClientVersion
        this.breakPoints = data.breakPoints
      })
    fetch('data/reports.json')
      .then(response => response.text())
      .then(text => {
        let data = JSON.parse(text)
        this.reports = data.reports
      })
  },
  watch: {
    report () {
      this.loadData()
    }
  },
  computed: {
    modifiedReports () {
      return this.reports.map(value => {
        return { value, text: value.replace('.json', '') }
      })
    },
    filteredData () {
      if (this.filter.length === 0)
        return this.data.customers
      return this.data.customers.filter(customer => this.filter.includes(customer.name))
    }
  },
  methods: {
    loadData () {
      this.loading = true
      fetch(`data/reports/${this.report}`)
        .then(response => response.text())
        .then(text => {
          this.data = JSON.parse(text)
          this.loading = false
        })
    },
    convertToUnit (value) {
      let key
      if (this.unit === 'KB')
        key = 1024
      else if (this.unit === 'MB')
        key = 1048576
      else if (this.unit === 'GB')
        key = 1073741824
      return `${Math.round((value / key) * 10) / 10} ${this.unit}`
    },
    convertToPercent (max, value) {
      return Math.round(((value / max) * 100) * 10) / 10
    },
    convertToColor (percent) {
      if (percent >= this.breakPoints.info && percent < this.breakPoints.warning)
        return "blue"
      else if (percent >= this.breakPoints.warning && percent < this.breakPoints.error)
        return "orange"
      else if (percent >= this.breakPoints.error)
        return "red"
      return "green"
    },
    getAllMailsByCustomer (value) {
      let mails = []
      this.data.customers.filter(customer => customer.name === value)[0].resources.map(resource => {
        if (resource.contact && resource.contact !== '-1' && resource.contact !== '-2')
          mails.push(resource.contact)
      })
      return mails.join(';')
    },
    checkClientVersion (value) {
      if (!value || value === '-1' || value === '-2') return false
      let version = value.match(/([[0-9].)+[0-9]/i)[0].replaceAll('.', '')
      return version < this.minClientVersion.replaceAll('.', '')
    },
    checkEntryState (value) {
      return value.contact === '-2' || value.client === '-2' || this.checkClientVersion(value.client) || this.convertToPercent(value.quota, value.usage) >= this.breakPoints.warning
    }
  }
}
</script>
